body {
  color: #5C6672;
}

.btn-success {
  background: #1B806A;
}

.btn-danger {
  background-color: #C34D49;
}

.label-default {
  background-color: #747474;
}

.ng-invalid:not(mat-radio-group).ng-touched:not(form, tr) {
    background: #FAEDEC;
    border: 1px solid #E85445;
}

.empty-table {
  display: flex;
  height: 150px;

}

.empty-table > div {
  margin: auto;
  text-align: center;
}

.empty-table > div > i {
  color: #26B99A;
  font-weight: bold;
  font-size: xx-large;
}

.empty-table > div > span {
  font-weight: bold;
  font-size: large;
}

// Color point into menu
li.current-page::before {
  background-color: white !important;
}

.table > thead > tr > th {
  vertical-align: top;
}

// Fix width of notifier
.notifier__notification-message {
  max-width: 390px;
}

div.status {
  height: 40px;
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

div.status>span {
  font-size: 21px;float: left;
  line-height: inherit;
  color: #333;
  margin-top: 4px;
}

div.status>div * {
  margin-left:10px;
}

.section {
  padding-top: 15px;
}

.spinner-fa {
  margin-top: 40px;
  margin-bottom: 45px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0,0,0,.4);
}

.title-traffication-bold {
  font-weight: bold;
}

.align-middle {
  vertical-align: middle !important;
}

footer {
  clear: both;
  position: relative;
  height: 52px;
  border-top: 1px solid #eee;
}

.remove-overlay table {
  position: relative;
  z-index: 1;
}
.nav-md .container.body .col-md-3.left_col {
  z-index: 3;
}

.icon-refund-transaction {
  margin-left: -2px;
  margin-right: 5px;
}

.icon-refund-transaction img {
  width: 26px;
  height: 18px;
}

.icon-refund-transaction img.small {
  width: 33px;
  height: 26px;
  margin-left: 8px;
}

.nav-sm ul.nav.child_menu_left {
  top: 0;
}

.nav-sm ul.nav.child_menu_left li a {
  text-align: center!important;
  font-weight: 400;
  font-size: 10px;
  padding: 10px 5px;
}

.nav-md ul.nav.child_menu_left li:before {
  background: #425668;
  bottom: auto;
  content: "";
  height: 8px;
  left: 23px;
  margin-top: 20px;
  position: absolute;
  right: auto;
  width: 8px;
  z-index: 1;
  border-radius: 50%;
}

.nav-md ul.nav.child_menu_left li:after {
  border-left: 1px solid #425668;
  bottom: 0;
  content: "";
  left: 27px;
  position: absolute;
  top: 0;
}

.nav-md .nav.child_menu_left li {
  padding-left: 36px;
}

.nav.child_menu_left>li>a {
  color: hsla(0,0%,100%,.75);
  font-size: 12px;
  padding: 9px;
}

.nav.child_menu_left>li.active>a {
  text-shadow: rgb(0 0 0 / 25%) 0 -1px 0;
  background: linear-gradient(#334556,#2C4257),#2A3F54;
  box-shadow: rgb(0 0 0 / 25%) 0 1px 0, inset rgb(255 255 255 / 16%) 0 1px 0;
}

.nav.child_menu_left li a:hover {
  background: rgba(255,255,255,.05);
  font-size: 12px;
  padding: 9px;
}
